// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3B1SRfW-CSepy4j1PzwP5a5wlDBuzyMk",
  authDomain: "aneen-9f883.firebaseapp.com",
  projectId: "aneen-9f883",
  storageBucket: "aneen-9f883.appspot.com",
  messagingSenderId: "237015747549",
  appId: "1:237015747549:web:4b1915719b68d82b038434",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const currentUser = auth.currentUser;
