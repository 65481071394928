import React from "react";
import classes from "./Posts.module.css";
import { IoEyeSharp } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";

const Posts = ({
  postsList,
  handlePostDelete,
  showPendingPosts,
  handlePostApproval,
  deleteLoading,
}) => {
  return (
    <>
      {postsList &&
        postsList.map((post, index) => {
          return (
            <div className={classes.postsList + " mb-4"} key={index}>
              <div className="d-flex">
                <div className={classes.postListImage}>
                  <img src={post.thumbnail} alt="Thumbnail" />
                </div>
                <div className={classes.postDetails}>
                  <h4>{post.title === "" ? "No Title" : post.title}</h4>
                  <div>
                    <div>{post.description}</div>
                    <div>
                      <FaUserAlt /> {post.uploader_details.name}
                      <IoEyeSharp className="ms-2" /> {post.views}
                    </div>
                    <div>
                      <BiCategory /> {post.category}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`ms-3 ${classes.postButtons}`}>
                <a
                  href={post.url}
                  className={"btn btn-outline-primary me-2 mb-2"}
                  target="_blank"
                  aria-disabled={deleteLoading}
                >
                  View
                </a>
                {showPendingPosts && (
                  <button
                    className="btn btn-outline-success me-2 mb-2"
                    disabled={deleteLoading}
                    onClick={() => {
                      console.log(post);
                      handlePostApproval(post.id);
                    }}
                  >
                    Approve
                  </button>
                )}
                <button
                  className="btn btn-outline-danger mb-2"
                  disabled={deleteLoading}
                  onClick={() => {
                    handlePostDelete(post);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Posts;
