import Modal from "react-bootstrap/Modal";
import classes from "./UserModal.module.css";
import { useState } from "react";
function UsersModal({ show, setUpdatedUsername, setShow, user }) {
  const [tempUsername, setTempUsername] = useState("");

  const handleUsernameUpdate = (event) => {
    setTempUsername(event.target.value);
  };

  const handleSubmit = () => {
    event.preventDefault();
    setUpdatedUsername(tempUsername);
  };
  return user ? (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update user's details
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className={"input-group w-100 " + classes.inp}>
            <p>Old Username:</p>
            <p className="ms-3">
              {" "}
              <b>{user.name}</b>
            </p>
          </div>
          <div className={"input-group w-100 " + classes.inp}>
            <p className="mt-2">New Username:</p>
            <input
              type="text"
              className="form-control ms-2 w-50"
              placeholder="Username"
              value={tempUsername}
              onChange={handleUsernameUpdate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-outline-primary me-2"}
            onClick={() => {
              setShow(false);
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  ) : (
    <Modal>"Close"</Modal>
  );
}
export default UsersModal;
