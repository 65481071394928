import React, { useEffect, useState } from "react";
import classes from "./PostsPage.module.css";
import Posts from "../components/Posts/Posts";
import { FaUserAlt } from "react-icons/fa";
import { MdTitle } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { Pagination } from "react-bootstrap";
import { useData } from "../context/DataContext";

const PostsPage = () => {
  const {
    deleteLoading,
    postsData,
    setPostsData,
    deletePostById,
    fetchAndUpdatePendingPost,
  } = useData();
  const [loading, setLoading] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [showPendingPosts, setShowPendingPosts] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25); // Change this to adjust the number of logs per page

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Generate an array of page numbers
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  // Handle page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Update current posts based on the current page
  var currentPosts = filteredPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  //Filter posts based on search and pending or active status
  useEffect(() => {
    const filterPosts = () => {
      const filtered = postsData.filter((post) => {
        const { title, uploader_details, category, is_pending } = post;
        const lowerCaseTitle = title ? title.toLowerCase() : "";
        const lowerCaseUser = uploader_details.name
          ? uploader_details.name.toLowerCase()
          : "";
        const lowerCaseCategory = category ? category.toLowerCase() : "";

        // Check if post matches search criteria and is_pending condition
        return (
          (searchTitle === "" ||
            (lowerCaseTitle && lowerCaseTitle.includes(searchTitle))) &&
          (searchUser === "" ||
            (lowerCaseUser &&
              lowerCaseUser.includes(searchUser.toLowerCase()))) &&
          (searchCategory === "" ||
            (lowerCaseCategory &&
              lowerCaseCategory.includes(searchCategory.toLowerCase()))) &&
          (showPendingPosts ? is_pending : !is_pending)
        );
      });

      setFilteredPosts(filtered);
      currentPosts = filteredPosts.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
      );
    };

    filterPosts();
  }, [postsData, searchTitle, searchUser, searchCategory, showPendingPosts]);

  // Deleting post
  const handlePostDelete = (post) => {
    setPostsData((prevPostData) =>
      prevPostData.filter((item) => item.id !== post.id)
    );
    deletePostById(post.id);
  };

  // Clear search inputs
  const handleClear = () => {
    setSearchTitle("");
    setSearchUser("");
    setSearchCategory("");
  };

  // Handle dropdown selection change
  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setShowPendingPosts(value === "Pending Posts");
  };

  return (
    <div className={classes.main}>
      <div className={"p-3 container "}>
        <h4 className={classes.mainText + " mb-3"}>
          <strong>Posts</strong>
        </h4>
        <h5>Search</h5>
        <nav className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <MdTitle size={16} />
            </span>
            <input
              type="text"
              className="form-control "
              placeholder="Search Title"
              value={searchTitle}
              onChange={() => setSearchTitle(event.target.value)}
            />
          </div>
          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <FaUserAlt size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search User"
              value={searchUser}
              onChange={() => setSearchUser(event.target.value)}
            />
          </div>
          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <BiCategory size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Category"
              value={searchCategory}
              onChange={() => setSearchCategory(event.target.value)}
            />
          </div>

          <div>
            <button
              className={`btn mb-3 ${classes.btnCustom}`}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </nav>
        <select
          value={showPendingPosts ? "Pending Posts" : "Active Posts"}
          onChange={handleDropdownChange}
          // className="mb-3"
        >
          <option value="Active Posts">Active Posts</option>
          <option value="Pending Posts">Pending Posts</option>
        </select>
        {loading && <div>Loading...</div>}
        {!loading && (
          <div className="d-flex justify-content-evenly align-items-center mt-4">
            <div className={"container"}>
              <Posts
                postsList={currentPosts}
                handlePostDelete={handlePostDelete}
                showPendingPosts={showPendingPosts}
                handlePostApproval={fetchAndUpdatePendingPost}
                deleteLoading={deleteLoading}
              />
            </div>
          </div>
        )}

        {!loading && (
          <div className="d-flex justify-content-center">
            <Pagination>
              {pageNumbers.map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}
      </div>
    </div>
  );
};
export default PostsPage;
