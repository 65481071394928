import React, { useEffect } from "react";
import classes from "./UserTable.module.css";

const UserTable = ({
  data,
  setShow,
  setUser,
  handleUserDelete,
  deleteLoading,
}) => {
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <table className={`table table-striped table-hover ${classes.table}`}>
      <thead>
        <tr className={"table-dark " + classes.thead}>
          <th className="col-md">Full name</th>
          <th className="col-md">Email</th>
          <th className="col-md">Registration Date</th>
          <th className="col-md ">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td className="col-md">{item.name}</td>
            <td className="col-md">{item.email}</td>
            <td className="col-md">{item.registration_time}</td>
            <td className="col-md">
              <button
                className={"btn btn-outline-primary m-1"}
                disabled={deleteLoading}
                onClick={() => {
                  setUser(item);
                  setShow(true);
                }}
              >
                Edit
              </button>
              <button
                className="btn btn-outline-danger m-1"
                disabled={deleteLoading}
                onClick={() => {
                  handleUserDelete(item);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;
