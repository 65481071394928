import Users from "./outlets/Users";
import Posts from "./outlets/PostsPage";
import Categories from "./outlets/Categories";
import WebsiteContent from "./outlets/WebsiteContent";
import PanelProtectedRoute from "./routes/PanelPrivateRoute";
import LoginProtectedRoute from "./routes/LoginPrivateRoute";
import Login from "./views/Login";
import Panel from "./views/Panel";

import { useScreenSize } from "./context/ScreenSizeDetector";
import { Route, Routes } from "react-router-dom";
import { useUser } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
function App() {
  const isMobile = useScreenSize();
  const { user } = useUser();

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PanelProtectedRoute user={user}>
              <Panel />
            </PanelProtectedRoute>
          }
        >
          <Route path="users" element={<Users />} />
          <Route path="posts" element={<Posts />} />

          <Route
            path="categories"
            element={
              <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <Categories />
              </DndProvider>
            }
          />

          <Route path="website-content" element={<WebsiteContent />} />
        </Route>
        <Route
          path="/login"
          element={
            <LoginProtectedRoute user={user}>
              <Login />
            </LoginProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
