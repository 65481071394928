import UserTable from "../components/usersTable/UserTable";
import UsersModal from "../utils/UserModal";
import classes from "./Users.module.css";
import React, { useState, useEffect } from "react";
import { MdEmail } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { useData } from "../context/DataContext";
import { Pagination } from "react-bootstrap";

const Users = () => {
  const {
    deleteLoading,
    tableData,
    setTableData,
    tableLoading,
    fetchAndUpdateName,
    deleteUserByEmail,
  } = useData();
  const [user, setUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState("a");
  const [UsersPerPage] = useState(25);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Update current users
  const indexOfLastUser = currentPage * UsersPerPage;
  const indexOfFirstUser = indexOfLastUser - UsersPerPage;
  const currentUsers =
    searchEmail === "" && searchUsername === ""
      ? tableData.slice(indexOfFirstUser, indexOfLastUser)
      : filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  //Filter users based on search
  useEffect(() => {
    const filterUsers = () => {
      const filtered = tableData.filter((user) => {
        const { email, name } = user;
        const lowerCaseEmail = email ? email.toLowerCase() : "";
        const lowerCaseUsername = name ? name.toLowerCase() : "";

        // Check if user matches search criteria
        return (
          (searchEmail === "" ||
            (lowerCaseEmail &&
              lowerCaseEmail.includes(searchEmail.toLowerCase()))) &&
          (searchUsername === "" ||
            (lowerCaseUsername &&
              lowerCaseUsername.includes(searchUsername.toLowerCase())))
        );
      });

      setFilteredUsers(filtered);
    };

    filterUsers();
  }, [tableData, searchEmail, searchUsername]);

  // Updating username
  useEffect(() => {
    user && fetchAndUpdateName(user.email, updatedUsername);
  }, [updatedUsername]);

  // Deleting user
  const handleUserDelete = (user) => {
    deleteUserByEmail(user.email, user);
  };

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Clear search inputs
  const handleClear = () => {
    setSearchEmail("");
    setSearchUsername("");
  };

  return (
    <div className="container p-3">
      <UsersModal
        user={user}
        setUpdatedUsername={setUpdatedUsername}
        show={show}
        setShow={setShow}
      />
      <div>
        <h4>
          <strong className={classes.mainText}>Users</strong>
        </h4>
        <h5 className="my-2">Search</h5>
        <nav className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <FaUserAlt size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Full name"
              value={searchUsername}
              onChange={() => setSearchUsername(event.target.value)}
            />
          </div>

          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <MdEmail size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Email"
              value={searchEmail}
              onChange={() => setSearchEmail(event.target.value)}
            />
          </div>

          <div>
            <button
              className={`btn mb-3 ${classes.btnCustom}`}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </nav>
      </div>
      <div>
        {tableLoading && <div className="mt-2">Loading...</div>}
        {!tableLoading && (
          <UserTable
            data={currentUsers}
            setUser={setUser}
            setShow={setShow}
            handleUserDelete={handleUserDelete}
            deleteLoading={deleteLoading}
          />
        )}
      </div>
      {!tableLoading && (
        <div className="d-flex justify-content-center">
          <Pagination>
            {Array.from(
              { length: Math.ceil(tableData.length / UsersPerPage) },
              (_, index) => (
                <Pagination.Item
                  key={index}
                  active={currentPage === index + 1}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default Users;
