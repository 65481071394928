import classes from "./PanelSidebar.module.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { ImStatsDots } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { MdLogout } from "react-icons/md";
import { BsFillPostcardHeartFill } from "react-icons/bs";
import { BiWorld, BiCategoryAlt } from "react-icons/bi";
import { useUser } from "../../context/UserContext";
import { useScreenSize } from "../../context/ScreenSizeDetector";

const PanelSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useUser();
  const navigate = useNavigate();
  const isMobile = useScreenSize();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="mb-5">
      <div
        id="sidebarMenu"
        className={`${classes.sidebar} ${isOpen ? classes.open : ""}`}
      >
        {isMobile && (
          <div className="position-absolute">
            <button
              className={classes.sandwichButton}
              onClick={toggleSidebar}
              aria-label={isOpen ? "Close Sidebar" : "Open Sidebar"}
            >
              {isOpen ? (
                <IoClose className={classes.sandwichButton} />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  className={classes.sandwichButton}
                />
              )}
            </button>
          </div>
        )}
        <div className="position-sticky pt-3">
          <div>
            <h2 className={classes.logo}>Admin Panel</h2>
          </div>
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink
                exact
                className={({ isActive }) => {
                  console.log("user is" + isActive);
                  return isActive ? "nav-link " + classes.active : "nav-link";
                }}
                onClick={() => setIsOpen(false)}
                to={"/users"}
              >
                <HiUsers size={20} className={"me-2"} />
                <span>Users</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={({ isActive }) => {
                  console.log("post is" + isActive);
                  return isActive ? "nav-link " + classes.active : "nav-link";
                }}
                onClick={() => setIsOpen(false)}
                to={"/posts"}
              >
                <BsFillPostcardHeartFill size={20} className={"me-2"} />
                <span>Posts</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={({ isActive }) => {
                  console.log("category is" + isActive);
                  return isActive ? "nav-link " + classes.active : "nav-link";
                }}
                onClick={() => setIsOpen(false)}
                to={"/categories"}
              >
                <BiCategoryAlt size={20} className={"me-2"} />
                Categories
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={({ isActive }) => {
                  console.log("website is" + isActive);
                  return isActive ? "nav-link " + classes.active : "nav-link";
                }}
                onClick={() => setIsOpen(false)}
                to={"/website-content"}
              >
                <BiWorld size={20} className={"me-2"} />
                <span>Website</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <div
                className="nav-link text-danger"
                onClick={() => {
                  logout().then(() => navigate("/login"));
                }}
              >
                <MdLogout size={20} className={"me-2"} />
                <span style={{ cursor: "pointer" }}>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelSidebar;
