import Modal from "react-bootstrap/Modal";
import classes from "./CategoryModal.module.css";
import CreatableSelect from "react-select/creatable";
import { useEffect, useState } from "react";
import { useData } from "../context/DataContext";
import { BiCategory } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const AddCategoryModal = ({
  show,
  setShow,
  handleAddNewCategory,
  lastCategoryOrder,
  setLastCategoryOrder,
}) => {
  const { categoriesData } = useData();
  const [tempData, setTempData] = useState({
    categoryIconUrl: "",
    categoryName: "",
    subcategories: [],
    categoryOrder: lastCategoryOrder + 1,
  });
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  var listOfExistingSubcategories = [];

  //  making collection of existing subcategories for options
  const getListOfSubcategories = () => {
    categoriesData.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        listOfExistingSubcategories.push(subcategory.name);
      });
    });

    const options = listOfExistingSubcategories.map((subcategory) => ({
      value: subcategory,
      label: subcategory,
    }));

    setSubcategoryOptions(options);
  };

  useEffect(() => {
    getListOfSubcategories();
  }, []);

  //  handles categories inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setTempData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //  handles addition of new subcategories and updating the previous one
  const handleSubcategoriesChange = (selectedOptions) => {
    const selectedSubcategories = selectedOptions.map((option) => option.value);

    // Check if there are any subcategories that need to be added to collection
    const missingSubcategories = selectedSubcategories.filter(
      (subcategory) => !listOfExistingSubcategories.includes(subcategory)
    );

    // Add the missing subcategories to the existing list of subcategories
    listOfExistingSubcategories = [
      ...listOfExistingSubcategories,
      ...missingSubcategories,
    ];

    setTempData((prevData) => ({
      ...prevData,
      subcategories: selectedSubcategories.map( (subcategory) => ({
        name: subcategory,
        id : uuidv4(),
      })),
    }));
  };

  const handleSubmit = () => {
    handleAddNewCategory(tempData);
    setLastCategoryOrder(lastCategoryOrder + 1);
    setShow(false);
  };

  return (
    tempData && (
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter justify-content-center"
            className="d-flex align-items-center "
          >
            <BiCategory className="me-2" />
           Add New Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <h6 htmlFor="categoryName" className="">
              Category Name
            </h6>
            <input
              className="form-control "
              id="categoryName"
              name="categoryName"
              value={tempData.categoryName}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group mb-2">
            <h6 htmlFor="categoryName" className="">
              Subcategories
            </h6>
            <CreatableSelect
              title="Subcategories"
              isMulti
              options={subcategoryOptions}
              onChange={handleSubcategoriesChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
          title="Submit"
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default AddCategoryModal;
